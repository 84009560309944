import { navigate } from '@reach/router';
import clsx from 'clsx';
import React from 'react';
import CallToAction from '../../../molecules/CallToAction/CallToAction';
import Icon from '../../../styles/atoms/icons';
import Hero404Styles from '../../../styles/Components/Hero/Hero404Styles';
import Link from '../../../utils/Link';
import { cardItems } from '../utils/mappings';

const NotFoundCard = ({ card }) => (
  <Link
    to={card.link}
    className={clsx(
      'card-main',
      card.link === '#chat-to-us' && 'chat-to-us'
    )}
  >
    <CardBody
      heading={card.heading}
      subtitle={card.subtitle}
      icon={card.icon}
      linkText={card.linkText}
    />
  </Link>
);

const CardBody = ({ heading, subtitle, icon, linkText }) => (
  <div className="card-link">
    <Icon id={icon} className="icon" isImage />
    <h5 className="heading-title">{heading}</h5>
    <p className="subtitle">{subtitle}</p>
    <p className="ext-link link">{linkText}</p>
  </div>
);

const Hero404 = () => (
  <Hero404Styles>
    <div className="hero-content">
      <Icon id="404-image" className="missing-page-image" isImage />
      <p className="title">404 ERROR</p>
      <h1>This page is waiting on parts...</h1>
      <p className="subhead">
        Sorry about that! Try one of the pages linked below to get you back on
        the road.
      </p>
      <div className="action-btns d-flex justify-content-center flex-md-row align-items-center">
        <CallToAction
          variant="tertiary"
          value="Go Back"
          icon="left"
          reverse
          customHandleClick={() => navigate(-1)}
        />
        <CallToAction
          link="/"
          variant="primary"
          value="Go to Homepage"
        />
      </div>
      <div className="cards-wrapper">
        {cardItems.map(item => (
          <NotFoundCard key={item.heading} card={item} />
        ))}
      </div>
    </div>
  </Hero404Styles>
);

export default Hero404;
