import styled from 'styled-components';
import { colors } from '../../atoms/colors';
import { font } from '../../atoms/typography';

export default styled.div`
  padding: 100px 0 110px;

  @media (max-width: 1200px) {
    padding-bottom: 40px;
  }

  @media (max-width: 768px) {
    padding-top: 48px;
    padding-bottom: 0;
  }

  .hero-content {
    max-width: 1164px;
    margin: 0 auto;
    text-align: center;
    padding: 0 23.5px;

    .title {
      ${font('overline', 'md', '700')}
      color: ${colors.primary[500]};
      margin-bottom: 24px;
    }

    svg.missing-page-image {
      display: block;
      margin-bottom: 56px;
      width: 100%;
      height: 260px;

      @media (max-width: 575px) {
        height: 45vw;
      }
    }

    h1 {
      margin-bottom: 16px;
    }

    .subhead {
      margin: 0 auto;
      color: var(--subtle-text-color);
      margin-bottom: 24px !important;
    }

    .action-btns {
      gap: 10px;
      flex-wrap: wrap;
    }

    .cards-wrapper {
      margin-top: 72px;
      width: 100%;
      display: flex;
      align-items: stretch;
      gap: 24px;
      justify-content: space-evenly;
      padding: 0 48px;

      @media (max-width: 1200px) {
        margin-top: 60px;
        margin-bottom: 24px;
        flex-wrap: wrap;
        justify-content: center;
      }

      @media (max-width: 768px) {
        padding: 0;
      }

      .card-main {
        display: block;
        flex: 0 0 33.333%;
        cursor: pointer;
        text-decoration: none;
        color: inherit;

        @media (max-width: 1200px) {
          flex-basis: calc(50% - 12px);
        }

        @media (max-width: 768px) {
          flex-basis: 100%;
        }

        .card-link {
          width: 100%;
          height: 100%;
          background: #e5e9ff;
          border-radius: 8px;
          padding: 24px;
          text-align: left;

          &:hover .ext-link::after {
            transform: translateX(8px);
          }

          .icon {
            margin-bottom: 20px;
            width: 40px;
            height: 40px;
          }

          .subtitle {
            margin-bottom: 12px;
          }

          .subtitle {
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.16px;
            color: #4d5061;
          }

          .ext-link {
            width: fit-content;
            ${font('text', 'sm', '600')}
            color: ${colors.primary[600]};
          }

          @media (max-width: 375px) {
            margin: 0 auto;
          }
        }
      }
    }
  }
`;
